import React from "react";
import arrow from "../../../images/arrowBlack.svg";
import Img from "../../../components/Img/Img";
import { useState } from "react";
import "./AktuellLinkMultiple.scss";
import Lightbox from "../../../components/Lightbox/Lightbox";

function AktuellLinkMultiple({ image, title, date, info, link }) {
  const [multipleExtended, setMultipleExtended] = useState(false);
  const [classListTrigger, setClassListTrigger] = useState(
    "aktuellLinkMultiple__arrow"
  );
  const [classExtendImage, setClassExtendImage] = useState(
    "aktuellLinkMultiple__img"
  );
  const triggerOnClick = () => {
    if (multipleExtended) {
      setMultipleExtended(false);
      setClassListTrigger("aktuellLinkMultiple__arrow");
      setClassExtendImage("aktuellLink__img");
    } else {
      setMultipleExtended(true);
      setClassListTrigger(
        "aktuellLinkMultiple__arrow aktuellLinkMultiple__arrow--rotate"
      );
      setClassExtendImage("aktuellLinkMultiple__img--extended");
    }
  };

  return (
    <div
      className={`aktuellLinkMultiple ${
        multipleExtended && "aktuellLinkMultiple--extended"
      }`}
    >
      <img
        className="styleTag__btnStyle"
        src={arrow}
        alt="&#10229;"
        className={classListTrigger}
        onClick={() => {
          triggerOnClick();
        }}
      />

      {(() => {
        if (!multipleExtended) {
          return (
            <>
              <Img
                cssClass={classExtendImage}
                path={image[0]}
                alt="Bild über aktuelles Ereigniss"
                onClick={() => {
                  triggerOnClick();
                }}
              ></Img>
              <div className="aktuellLink__textWrapper">
                <h3 className="aktuellLink__h3">{title}</h3>
                <span className="aktuellLink__date">{date}</span>
                <p className="aktuellLink__text">{info}</p>
                {link()}
              </div>
            </>
          );
        } else {
          return (
            <>
              <h3 className="aktuellLink__h3">{title}</h3>
              <span className="aktuellLink__date">{date}</span>
              <div className="aktuellLinkMultiple__imgWrapper">
                {image.map((img, index) => {
                  return (
                    <div
                      key={index}
                      className="aktuellLinkMultiple__img--extended"
                    >
                      <Lightbox small={img}></Lightbox>
                    </div>
                  );
                })}
              </div>
              <p className="aktuellLink__text">{info}</p>
              {link()}
            </>
          );
        }
      })()}
    </div>
  );
}

export default AktuellLinkMultiple;
