import React from "react";
import "./AktuellLink.scss";
import { Link as RoutingLink } from "gatsby";
import AktuellLinkText from "./AktuellLinkText";
import AktuellLinkImage from "./AktuellLinkImage";
import AktuellLinkMultiple from "./AktuellLinkMultiple";

function AktuellLink({ date, image, link, info, title }) {
  const imageCount = image && image.length === 1 ? "single" : "multiple";

  const linkComponent =
    link &&
    (() => {
      const isInternal = link.charAt(0) === "/";
      if (isInternal) {
        return (
          <RoutingLink to={link} className="aktuellLink__link">
            →link
          </RoutingLink>
        );
      } else {
        return (
          <a href={link} className="aktuellLink__link" target="_blank">
            →link
          </a>
        );
      }
    });

  return (
    <>
      {(() => {
        if (image && imageCount === "single") {
          return (
            <AktuellLinkImage
              image={image}
              title={title}
              date={date}
              info={info}
              link={linkComponent}
            ></AktuellLinkImage>
          );
        } else if (image && imageCount === "multiple") {
          return (
            <AktuellLinkMultiple
              image={image}
              title={title}
              date={date}
              info={info}
              link={linkComponent}
            ></AktuellLinkMultiple>
          );
        } else {
          return (
            <AktuellLinkText
              title={title}
              date={date}
              info={info}
              link={linkComponent}
            ></AktuellLinkText>
          );
        }
      })()}
    </>
  );
}

export default AktuellLink;
