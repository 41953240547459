import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "./aktuell.scss";
import useTitleContext from "../../context/useTitleContext";
import AktuellLink from "./AktuellLink/AktuellLink";
import Seo from "../../components/seo";
import BackBtn from "../../components/BackBtn/BackBtn";

function Aktuell() {
  const data = useStaticQuery(graphql`
    query Aktuell {
      markdownRemark(frontmatter: { queryId: { eq: "aktuell" } }) {
        frontmatter {
          Content {
            date
            link
            title
            info
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.5)
              }
            }
          }
        }
      }
    }
  `);

  const dataArray = data.markdownRemark.frontmatter.Content;

  useTitleContext("Aktuell");

  return (
    <div className="container aktuell">
      <Seo
        title="Aktuell"
        description="Aktuelle Ausstellungen und Projekte von Florian Haas."
      ></Seo>
      <BackBtn></BackBtn>
      <main className="aktuell__linkWrapper">
        {dataArray.map(entry => {
          const { date, image, link, info, title } = entry;
          return (
            <AktuellLink
              key={date}
              date={date}
              image={image}
              link={link}
              info={info}
              title={title}
            ></AktuellLink>
          );
        })}
      </main>
    </div>
  );
}

export default Aktuell;
