import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./Img.scss";

function Img({ path, alt = "", cssClass = "", onClick: cb = null }) {
  const image = getImage(path);

  return (
    <div
      className={`ImgWrapper ${cssClass} ${cb && "ImgWrapper__cursorPointer"}`}
      onClick={cb}
    >
      <GatsbyImage image={image} alt={alt} />
    </div>
  );
}

export default Img;
