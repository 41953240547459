import React from "react";
import Img from "../../../components/Img/Img";

function AktuellLinkImage({ image, title, date, info, link, fit = "cover" }) {
  return (
    <div className="aktuellLink">
      <Img
        path={image[0]}
        alt="Bild über aktuelles Ereigniss"
        cssClass="aktuellLink__img"
      ></Img>
      <div className="aktuellLink__textWrapper">
        <h3 className="aktuellLink__h3">{title}</h3>
        <span className="aktuellLink__date">{date}</span>
        <p className="aktuellLink__text">{info}</p>

        {link()}
      </div>
    </div>
  );
}

export default AktuellLinkImage;
