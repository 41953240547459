import React from "react";

function AktuellLinkText({ title, date, info, link }) {
  return (
    <div className="aktuellLink aktuellLink--withoutImage">
      <div className="aktuellLink__textWrapper">
        <h3 className="aktuellLink__h3">{title}</h3>
        <span className="aktuellLink__date">{date}</span>
        <p className="aktuellLink__text">{info}</p>
        {link()}
      </div>
    </div>
  );
}

export default AktuellLinkText;
